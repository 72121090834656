.search-page {
   font-size: 12px;
   .header-tien-ich {
      cursor: pointer;
   }
   .list-navbar-items {
      ul {
         li {
            position: relative;
            height: 60px;
            line-height: 60px;
           
            &:hover::before {
               width: 100%;
               height: 3px;
            }
            &:hover a {
               color: $color-default !important;
            }
            a {
               display: block;
               font-size: 13px;
               color: #fff;
               text-transform: uppercase;
            }
            a.active {
               position: relative;
               color: $color-default !important;
               &::before {
                  content: "";
                  width: 100%;
                  height: 3px;
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  margin: 0 auto;
                  background-color: $color-default;
                  transition: width 0.3s;
               }
            }
         }
      }
   }
   .align-items-top {
      height: 60px;
      align-items: center;
   }
   .logo-desktop img{
      margin-top: 3px;
   }

   ul {
      li {
         i.fa-search {
            border: 1px solid #aaa;
            padding: 10px;
            border-radius: 50px;
         }
         i {
            border: 2px solid #ccc;
            padding: 5px;
            width: 35px;
            font-size: 20px;
            text-align: center;
            border-radius: 5px;
         }
      }
   }
   .header-col-set {
      padding: 0px;
      .search-header {
         .header-set-input-search {
            border: 1px solid $color-default;
            border-radius: 0px;
            font-family: inherit;
            &:focus {
               border-color: $color-default !important;
            }
         }
         .header-set-input-search:focus {
            color: #495057;
            background-color: #fff;
            border-color: #a1a1a1;
            outline: 0;
            box-shadow: none;
         }
         .header-set-btn-search {
            border-radius: 0px;
            background-color: $color-default;
            border: 1px solid $color-default;
            outline: none;
         }
         .btn-secondary:not(:disabled):not(.disabled):active:focus,
         .btn-secondary:not(:disabled):not(.disabled).active:focus,
         .show > .btn-secondary.dropdown-toggle:focus {
            box-shadow: none;
         }
         .btn:focus,
         .btn.focus {
            outline: 0;
            box-shadow: none;
         }
      }

      .header-card-text {
         margin-left: $gap;
         font-size: $font-size-default;
      }
   }
   .user-action {
      .username {
         position: relative;
         .list-action {
            display: none;
            position: absolute;
            ul {
               li {
                  padding: 6px 15px;
               }
            }
         }
         &:hover .list-action {
            position: absolute;
            display: block;
            right: 0;
            z-index: 10;
            ul {
               li {
                  &:hover {
                     background-color: #ececec;
                  }
               }
            }
         }
      }
      .shopping-cart {
         .header-set-text-primary {
            color: $color-default;
            font-size: $font-size-default;
            float: right;
         }
         .header-badger {
            height: 15px !important;
            width: 15px !important;
            font-size: 10px;
            position: absolute;
            top: -5px;
         }
         .mobile-menu-icon {
            outline: none;
            border: none;
            background-color: transparent;
            .icon-header {
               color: $color-default;
            }
         }
         .count-cart {
            width: 18px;
            height: 18px;
            background-color: red;
            text-align: center;
            border-radius: 18px;
            top: -10px;
            right: 0;
            span {
               font-size: $font-size-default - 3;
               position: relative;
               top: -2px;
            }
         }
      }
   }
   .search-header {
      padding: 0 15px;
   }
   .ui-effects-wrapper {
      display: contents;
   }
}
.header-top {
   margin-right: 15px;
   &:last-child {
      margin-right: 0;
   }
   border-radius: 12px;
   padding: 10px 20px;
   border: 1px solid #888888;
   display: flex;
   align-items: center;
}
.compare-list-products {
   .compare-list-detail {
      display: flex;
      flex-flow: wrap;
      .compare-list-item {
         float: left;
         width: calc(1 / 5 * 100% - (1 - 1 / 5) * 20px);
         margin: 0 15px 15px 0;
         background: #fff;
         box-shadow: 0 4px 6px #00000029;
         border-radius: 8px;
         text-align: center;
         position: relative;
         .compare-list-item-image {
            margin: 20px auto 0 auto;
            img {
               height: 250px;
               object-fit: contain;
               transition: 0.4s ease;
            }
            &:hover img {
               // transform: scale(1.05);
            }
         }

         .compare-list-item-info {
            padding: 0 10px 15px 10px;
            button {
               padding: 6px $gap;
            }
         }
      }
   }
}

.list-items-button {
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   list-style: none;
   .item-action {
      .icon_read{
         &:hover{
            display: none !important;
         }
         &:hover .img-2 {
            display: block;
            margin: 0 auto;
         }
      }
      border: 1px solid $color-default;
      width: calc((100% / 3) - 220px);
      height: 120px;
      margin: 40px 70px 0;
      border-radius: 10px;
      text-align: center;
      color: white;
      &::before {
         content: "";
         padding-top: 100%;
      }
      .box-content {
         border-radius: 10px;

         img {
            width: 50px;
            height: 50px;
         }
         .img-2 {
            display: none;
         }
         &:hover {
            background-color: $color-default;
         }
         &:hover p {
            color: #fff !important;
         }
         &:hover img {
            display: none;
         }
         &:hover .img-2 {
            display: block;
            margin: 0 auto;
         }
      }
   }
}
.menu-user {
   li {
      &:nth-last-child {
         border-top: 1px solid $color-default;
      }
   }
   button {
      span {
         font-size: $font-size-default;
      }
   }
}

//modal login , register
.modal-login {
   .sign-in-app {
      .sign-in-facebook {
         background: #4267b2;
         border: none;
         box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
         font-size: 14px !important;
      }
      .sign-in-google {
         box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
         border: 1px solid #dfdfdf !important;
         border-radius: 4px !important;
         font-size: 14px !important;
      }
   }
   .col-md-5 {
      padding-right: 30px;
   }
   .col-md-7 {
      border-left: 1px solid #dfdfdf;
      padding-left: 30px;
   }
   .border-line {
      border-bottom: 2px solid #cfcfcf;
      position: relative;
      height: 10px;
      margin: 25px 0;
      justify-content: center;
      display: flex;
      p {
         position: absolute;
         background: #fff;
         padding: 0 10px;
         font-size: 14px;
         font-weight: bold;
      }
   }
   .cursor-pointer {
      &:hover {
         text-decoration: underline;
      }
   }
}

.modal-register {
   .sex {
      input {
         width: 22px;
         height: 20px;
         margin: 0;
      }
   }
}

.form-create-account {
   border-radius: 5px;
   background-color: #fff;
   box-shadow:
      rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
   .col-md-5 {
      padding-left: 0;
      .left-part {
         position: relative;
         background-color: #63a4ff;
         background-image: linear-gradient(315deg, #63a4ff 0%, #52d2db 65%);
         height: 100%;
         .fas.fa-envelope {
            position: absolute;
            left: 50%;
            bottom: 50%;
            transform: rotate(-20deg);
         }
         .fas.fa-at {
            position: absolute;
            left: 25%;
            bottom: 65%;
            transform: rotate(20deg);
         }
         .fas.fa-phone {
            position: absolute;
            left: 30%;
            bottom: 25%;
            transform: rotate(0deg);
         }
      }
   }

   .form-horizonal {
      padding: 0 20px;
   }
}
.form-register {
   img {
      position: absolute;
      bottom: 0;
      top: 45%;
      object-fit: cover;
   }
}

//chinh-sach
.chinh-sach {
   margin: 50px 0px;
   .chinh-sach-bao-hanh-chung {
      .flowchart {
         max-width: 600px;
         margin: auto;
      }
   
      .item {
         position: relative;
         background: white;
         border: 1px solid $color-default;
         padding: 10px;
         margin-bottom: 30px;
      }
   
      .content-detail {
         text-align: center;
      }
   
      .arrow {
         position: absolute;
         bottom: -28px;
         left: 50%;
         transform: translateX(-50%);
         width: 7px;
         height: 7px;
         display: inline-block;
         &::after {
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-right-style: solid;
            border-right-width: 1px;
            content: "";
            display: inline-block;
            height: 8px;
            left: 0;
            position: absolute;
            top: 0;
            width: 8px;
         }
     
      }
      .arrow::after,
      .arrow .arrow::after {
         border-color:$color-default;
      }
   
      .arrow::before,
      .arrow .arrow::before {
         background-color: $color-default;
      }
      .arrow.is-bottom {
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
      }
      .arrow.arrow-bar {
         &::before {
            bottom: 0px;
            content: "";
            height: 27px;
            position: absolute;
            right: 0;
            transform-origin: bottom;
            width: 1px;
            transform: rotate(-45deg);
         }
      }
   
      .item:last-child .arrow {
         display: none;
      }
   }
   .chinh-sach-huy-doi-tra,.chinh-sach-bao-hanh-giga, .chinh-sach-tra-hoan-tien {
  
      table {
         tr {
            th, td {
               border: 1px solid #ccc;
               padding: 5px;
            }
         }
      }
   }
}
.tra-cuu {
   margin-top: 30px;
   .container {
   }
   .content {
      display: block !important;
      position: relative;
      top: 0;
      margin: 0 auto;
      max-width: 1000px;
   }
   fieldset {
      border-radius: 15px;
      border-width: 2px;
      padding: 30px;
   }
}
.kich-hoat {
   margin-top: 100px;
   margin-bottom: 12%;
}
.yeu-cau {
   margin-top: 50px;
   margin-bottom: 50px;
   // height: calc(100% - 400px);
   .col-md-8 {
      position: relative;
      .form-control.search {
      }
      .dataSearchProducts {
         padding: 0 10px;
         position: absolute;
         width: 100%;
         background-color: #fff;
         border: 1px solid #ddd;
         z-index: 99;
         .border-bottom-1 {
            padding: 5px 0;
         }
      }
   }
   label.position-relative,
   button.hoplongtech-btn-primary {
      &:hover span.toolTip {
         visibility: visible;
         opacity: 1;
      }
      &:hover span.toolTip-button {
         visibility: visible;
         opacity: 1;
      }
      span.toolTip {
         font-size: 14px;
         visibility: hidden;
         min-width: 225px;
         background-color: #555;
         color: #fff;
         text-align: center;
         border-radius: 6px;
         padding: 5px;
         position: absolute;
         z-index: 1;
         top: 78%;
         left: 0;
         opacity: 0;
         transition: opacity 0.3s;
         &::after {
            content: "";
            position: absolute;
            top: -20%;
            left: 20%;

            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #555 transparent;
         }
      }
      span.toolTip-button {
         font-size: 14px;
         visibility: hidden;
         min-width: 225px;
         background-color: #555;
         color: #fff;
         text-align: center;
         border-radius: 6px;
         padding: 5px;
         position: absolute;
         z-index: 1;
         top: -15%;
         left: 80px;
         opacity: 0;
         transition: opacity 0.3s;
         &::after {
            content: "";
            position: absolute;
            top: 30%;
            left: -10px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #555 transparent transparent;
         }
      }
   }
}

.tram-bao-hanh {
   margin-top: 100px;
   margin-bottom: 100px;
   background-color: #fff;
   box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
   .content {
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      display: flex;
      flex: 1 1;
      flex-direction: row;
      .content-info {
         flex: 2;
         .item-list-map {
            p {
               margin: 10px 0;
            }
            .align-items-center {
               border-bottom: 1px solid #ddd;
               padding: 0 15px;
               .bg-transparent {
                  min-width: 120px;
                  &:hover {
                     color: #0e71b9;
                     cursor: pointer;
                     text-decoration: underline;
                  }
               }
            }
         }
      }
      .content-map {
         flex: 3;
         padding: 20px 10px 20px 20px;
         p {
            iframe {
               width: 100%;
            }
         }
      }
   }
   .vertical-line {
      border-left: 1px solid #ddd;
   }
}